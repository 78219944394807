import "../scss/core.scss";
import "../css/site.css";


import "./shopping-cart.ts";
import "jquery-ui/ui/effect";
import { LazyImage } from "./lazy-background-image";
import { Svg } from "./svgImageLoader";
import { Components } from "./components";

async function initCoreFunctions() {
    try {
        const coreModule = await import(/* webpackChunkName: "site-core-functions" */"./site-core-functions");
        new coreModule.Core.CoreFunction().init();
    } catch (err) {
        console.error("Unable to load 'siteCoreJs'.", err);
    }
}
initCoreFunctions();

namespace TextSearch {
  export interface ITextSearchBox {
    init();
  }

  export class TextSearchBox implements ITextSearchBox {
    textSearchPanel: HTMLElement;
    textSearch: HTMLInputElement;
    filterPanel: HTMLElement;
    filterItems: HTMLElement[];
    refreshTimeout: number;

    constructor(autocompleteElement: HTMLElement) {
      this.textSearchPanel = autocompleteElement;
      this.textSearch = this.textSearchPanel.querySelector("[type=search]");
      const filterPanelSelector = this.textSearchPanel.getAttribute("data-filter-container");
      this.filterPanel = document.querySelector(filterPanelSelector);
      this.filterItems = Array.from(this.filterPanel.children).map(x => x as HTMLElement);
    }

    init() {     
      this.textSearch.addEventListener("keyup", () => this.refreshItems());
      this.textSearch.addEventListener("search", () => this.refreshItems());
    }

    refreshItems() {
      window.clearTimeout(this.refreshTimeout);
      this.refreshTimeout = window.setTimeout(() => this.filter(), 600);
    }

    filter() {
      const value = String(this.textSearch.value);     
      const regexp = new RegExp(`^.*${value}.*$`, 'i');
      for (const item of this.filterItems) {
        const itemValue = item.getAttribute("data-filter");    
        if (regexp.test(itemValue)) {
          item.hidden = false;
        } else {
          item.hidden = true;
        }
      }
    }

    clearFilter() {
      this.filterItems.forEach(x => x.hidden = false);
      this.textSearch.value = "";
    }
  }
}

namespace Menu {
    export class MenuHighlight {
        init() {
            const path = document.location.pathname;
            const index = path.indexOf("/", 1);
            const activePath = path.substr(0, index);
            const links = $(`div.sub-menu a[href^='${activePath}']`);
            if (links.length > 0) {
                const menulink = $(links[0]);
                const activeTopMenu = menulink.parents(".sub-menu").parent();
                activeTopMenu.addClass("active-top-menu");
            }
        }
    }
}


window.addEventListener('message', event => {    
    if (event.data.message === "mark-online-ready") {
        $("#mark-online-loader").hide();
        return;
    }

    if (event.data.message !== "reset-token") {
        return;
    }
    const markOnline = $("#mark-online");
    if (markOnline.length > 0) {
        const currentUrl = markOnline.attr("data");
        const splitted = currentUrl.split("?token");
        if (splitted.length === 2) {
            markOnline.attr('data', splitted[0]);
        }
    }
});

$(() => {

    $("[data-single-click-only=true]").on('click',
        (x) => {
            $(x.target).prop("disabled", true);
            $(x.target).attr("disabled", "disabled");
            $("div[name=loader]").removeAttr("hidden");
        });
    if ($("[data-single-click-only=true]").length) {
        $('body').append('<div name="loader" hidden class="loader-dual-ring black" ></div><div name="loader" hidden class="lightbox full-screen"></div>');
    }

    const svgLoader: Svg.IImageLoader = new Svg.ImageLoader();
    svgLoader.init("svg[data-href]");

    const loginButton: Components.ILoginButton = new Components.LoginButton($("[data-login-button=true]"));
    loginButton.init();
    const lazyBackground: LazyImage.ILazyBackgroundImage = new LazyImage.LazyBackgroundImage();
    lazyBackground.init($("[data-lazy-background-image]"));

    $("[data-text-search]").each((index, item) => {
        const textSearch: TextSearch.ITextSearchBox = new TextSearch.TextSearchBox(item);
        textSearch.init();
    });

    if ($("[data-close-confirmation-message]").length > 0) {
        window.onbeforeunload = () => {
            return $("[data-close-confirmation-message]").data("close-confirmation-message");
        };
    }

    const menuHighlight = new Menu.MenuHighlight();
    menuHighlight.init();

    $("[data-show]").on("click",
        function() {
            $($(this).data("show")).slideDown(100);
            $(this).slideUp(100);
        });

    $("select[data-show-hide]").on("change",
        function() {
            $(this).find("option:selected").each(function() {
                const optionValue = $(this).attr("value");
                if (optionValue) {
                    const forShowSelector = `[data-show-when="${optionValue}"]`;
                    $("[data-show-when]").not(forShowSelector).slideUp(500);
                    $(forShowSelector).slideDown(500);
                } else {
                    $("[data-show-when]").slideUp(500);
                }
            });
        }).trigger("change");

    function resizeGallery() {
        var heightPanel = document.querySelector(".module-cover-slides .carousel-inner .carousel-item.define-gallery-height");
        heightPanel.setAttribute("style", `display: block;`);

        var getHeightSlide = document.querySelector(".module-cover-slides .carousel-inner .carousel-item.define-gallery-height .module-cover-small");
        var galleryPanel = document.querySelector(".module-cover-slides .carousel-inner");
        if (!getHeightSlide || !galleryPanel) {
            return;
        }
        var domRect = getHeightSlide.getBoundingClientRect();
        galleryPanel.setAttribute("style", `height: ${(domRect.height + 0.05)}px;`);
        heightPanel.removeAttribute("style");
    };


    function expireAction(showDiv: string, makeDisabled: string) {
        (document.querySelector(showDiv) as HTMLElement).style['display'] = 'block';
        (document.querySelector(makeDisabled) as HTMLElement).setAttribute('disabled', 'true');
    }

    var expireTag = document.querySelector("[data-expire-function]");
    if (expireTag) {
        const expireDate: number = +expireTag.getAttribute('data-expire-function');
        const showDiv: string = expireTag.getAttribute('data-show');
        const makeDisabled: string = expireTag.getAttribute('data-make-disabled');
        const timeout = expireDate - new Date().getTime();
        if (timeout > 0) {
            if (timeout < 2123143745) { //to big timeout exexute now
                setTimeout(() => expireAction(showDiv, makeDisabled), timeout)
            }
        } else {
            expireAction(showDiv, makeDisabled);
        }
    }

    if (document.querySelector(".module-cover-slides .carousel-inner .carousel-item.define-gallery-height")) {
        window.addEventListener('resize', (event) => { resizeGallery(); }, true);
        window.addEventListener("load", () => { resizeGallery(); });

        if (document.readyState !== 'loading') {
            resizeGallery();
        } else {
            document.addEventListener('DOMContentLoaded', resizeGallery);
        }
    }
});
